import React, {useState} from "react";
import { Button, Card, Divider, List, Typography } from "antd";
import { CaretUpOutlined, CaretDownOutlined, DownCircleFilled } from "@ant-design/icons"
import HandshakeCardSeller from "./HandshakeCardSeller";

/**
  ~ What it does? ~

  Creates grid layout of outbound (seller) handshakes

  ~ How can I use? ~
  UPDATE LATER
  <Events
    contracts={readContracts}
    contractName="YourContract"
    eventName="SetPurpose"
    localProvider={localProvider}
    mainnetProvider={mainnetProvider}
    startBlock={1}
  />
  Handshake({ contracts, contractName, eventName, localProvider, mainnetProvider, startBlock })
**/


export default function HCardSellerList(props) {

// Get ensProvider from props
const { ensProvider } = props;

// Get seller address from props
let seller = props.address;
let apiCall = "https://omu0yb846i.execute-api.us-east-1.amazonaws.com/prod/"+seller;
var nftData = [];

  // 📟 Get all Handshakes for this seller
const [apiState, updateapiState] = useState("loading"); // Set initial API state
const [addressState, setAddressState] = useState(false); // Set initial address Bool
const [nftCompData, setNftCompData] = useState(); // Set initial NFT data
const [showAllShakes, setShowAllShakes] = useState(false); // Set initial show/hide all state
const [demoState, setDemoState] = useState(false);

// API Call to get transactions from AWS
var APIGetCall = async () => {
    // instantiate a headers object
  var myHeaders = new Headers();
  // add content type header to object
  myHeaders.append("Content-Type", "application/json");
  // using built in JSON utility package turn object to string and store in a variable
  var raw = JSON.stringify(); //Leave Blank because its a GET call
  // create a JSON object with parameters for API call and store in a variable
  var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
  };
  // make API call with parameters and use promises to get response
  let result = await fetch(apiCall, requestOptions)
  .then((response) => { //THIS IS HOW YOU ACCESS THE RESPONSE DONT DELETE
    return response.json()})

    nftData = result.Items;
    setNftCompData(nftData);
    updateapiState("loaded");
  return result.Items;  
}

// Trigger API Call on seller address loading properly
if (seller && seller.length == 42 && !addressState) { 
  setAddressState(true);
  APIGetCall();
  updateapiState("Pending");
} else if (!demoState && !seller && seller.length != 42) {
  setDemoState(true);
  let nowTemp = new Date();
  const backDate = new Date(nowTemp.getTime() - 1000 * 60 * 60 * 0.25 );
  const backDate2 = new Date(nowTemp.getTime() - 1000 * 60 * 60 * 2 * 24 );
  let demoNow = backDate.toISOString();
  let demoNow2 = backDate2.toISOString();
  setNftCompData([{ApproxBlockNumber : "15540356",
Buyer: "0x3AFA32FDbbe2eF9118Cdf020ae972880C00Fd61E",
CollectionAddress: "0xA247393d4d270Df7bC888b619f3242C8916Bab03",
CollectionTitle: "Moonbirds",
DateTime: demoNow,
ImageURL: "https://cdn.center.app/1/0x23581767a106ae21c074b2276D25e5C3e136a68b/6/5e8fae303699c0e9f0e5860b4847f70441de4fbf096e55f78d88bf48873b7576.png",
NFTURL: "https://center.app/collections/0x23581767a106ae21c074b2276D25e5C3e136a68b/6",
Price: "10000000000000000000",
Seller: "0x3AFA32FDbbe2eF9118Cdf020ae972880C00Fd61E",
Status: "Pending",
TokenID: "6",
TransactionID: "0"},
{ApproxBlockNumber : "15540356",
Buyer: "0x3AFA32FDbbe2eF9118Cdf020ae972880C00Fd61E",
CollectionAddress: "0x06012c8cf97BEaD5deAe237070F9587f8E7A266d",
CollectionTitle: "CryptoKitties",
DateTime: demoNow2,
ImageURL: "https://cdn.center.app/1/0x06012c8cf97BEaD5deAe237070F9587f8E7A266d/10/20bf42541c74e482929d4ccde6f35cb841ac321c4a7ed49053105bf7c9ab6145.png",
NFTURL: "https://center.app/collections/0x06012c8cf97BEaD5deAe237070F9587f8E7A266d/10",
Price: "1000000000000000000",
Seller: "0x3AFA32FDbbe2eF9118Cdf020ae972880C00Fd61E",
Status: "Accepted",
TokenID: "10",
TransactionID: "0"}])
updateapiState("loaded");
}

const handshakeIsExpired = (dateTime) => {
  // Get Time Remaining on Handshake
  let HSTime = new Date(dateTime);
  let now = new Date();
  let timeDiff = now - HSTime;
  let minutesLeft = Math.ceil(timeDiff / (1000 * 60));
  return minutesLeft >= 60
}


const handshakeGrid = (nftGridData) => {
        return <List
          rowKey = {"data.TransactionID"} // Idk why but this doesn't work
                grid={{
                  gutter: 16,
                  column: 3,
                }}
            dataSource={nftGridData}
            renderItem={(item) => (
              
              <List.Item>
              <HandshakeCardSeller 
                address={props.address}
                data={item}
                userSigner={props.userSigner} 
                gasPrice={props.gasPrice}
                writeContracts={props.writeContracts}
                ensProvider={ensProvider}
              /> 
              </List.Item>
              
            )}
          />
}


if (apiState == "loaded") {
	return (
		<>
    <Divider orientation="left">Created Handshakes in Progress</Divider>
    {handshakeGrid(nftCompData.filter(i => i.Status == 'Pending' && !handshakeIsExpired(i.DateTime)))}
    <Divider orientation="left">
      <span style = {{cursor: 'pointer'}} 
      onClick={() => {setShowAllShakes(!showAllShakes)}}>
        <Button size="large">View All Created Handshakes {showAllShakes ? <DownCircleFilled/>: <DownCircleFilled/>}</Button></span>
    </Divider>
    {showAllShakes && handshakeGrid(nftCompData.filter(i => 
      i.Status !== 'Pending' || (i.Status == 'Pending' && handshakeIsExpired(i.DateTime))
    ))}
    
		</>
	);
}
else {
    return (
        <>
        <Divider orientation="left">Created Handshakes In Progress</Divider>      
      
        
      
      <Button type="dashed" onClick={() => {
                        APIGetCall(seller); 
                      }}>Loading Handshakes...</Button>
     </>
    );
  }
}




