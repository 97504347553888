import React, {useState, useEffect, useReducer} from "react";
import { Avatar, Alert, Badge, Button, Card, Divider, List, Row, Col, Typography, AutoComplete, Input } from "antd";
import { SearchOutlined} from '@ant-design/icons';
import NFTcard from "./NFTcard";
import { Token } from "graphql";
import { useCallback } from "react";
import { CENTER_API_KEY } from "../constants"
import { RequestEthereumAccountsResponse } from "walletlink/dist/relay/Web3Response";

/**
  ~ What it does? ~

  Displays an NFT Card grid

  ~ How can I use? ~ NEED TO UPDATE DOCUMENTATION

  UPDATE LATER
  <Events
    contracts={readContracts}
    contractName="YourContract"
    eventName="SetPurpose"
    localProvider={localProvider}
    mainnetProvider={mainnetProvider}
    startBlock={1}
  />
  Handshake({ contracts, contractName, eventName, localProvider, mainnetProvider, startBlock })
**/


export default React.memo(function NFTcardGrid(props) {

// Set variables from props
let seller = props.address;
const network = "ethereum-mainnet"; //Set network

// API Request
// API Header/Response Data
var myHeaders = new Headers();
myHeaders.append("X-API-Key", CENTER_API_KEY); //API Key in constants file

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

  // API Call State Management - 2 Calls. 1st to get NFT's in connected wallet data, 2nd to get NFT metadata to pass to List component for rendering
    // State 1: loading - Default State while waiting for wallet info to load (Need connected wallet address for API Call)
    // State 2: init - Wallet info loaded, but no data yet -> Starts making Wallet API Call with wallet address
    // State 3: walletSuccess - Wallet info loaded and 1st API Call complete
    // State 4: pendingNFTData - Wallet info loaded and 2nd API Call complete
    // State 5: NFTDataSuccess - 2nd API Call complete, renderNFT update initiated, and will start to render when it's ready
    // Other State: noData - No data found with initial wallet call, attempts to repeat wallet call. TODO: Add a limited retry attempt and display "No NFTs Found"


  // Critical Variables for state change conditions
  const [apiState, updateapiState] = useState("loading");
  const [renderNFT, updaterenderNFT] = useState([]);
  const [nftItems, updatenftData] = useState();
  const [walletAPICall, updateWalletAPICall] = useState('');
  const [addressState, setAddressState] = useState(false);
  const [demoState, setDemoState] = useState(false);
  const [nftTempState, setNFTTempState] = useState(false);
  const [renderState, setRenderState] = useState(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);


  // Set walletAPICall when seller is set
  if (seller && seller.length == 42 && !addressState) {
    setAddressState(true);
    setRenderState(false);
    updateWalletAPICall("https://api.center.dev/v1/"+network+"/account/"+seller+"/assets-owned?limit=100");
    updateapiState("init");
  }
  else if (!demoState && !seller && seller.length != 42) {
    setDemoState(true);
    updaterenderNFT(
      [{address: "",
    collection_name: "Very Fun 1",
    token_id: "Example NFT - 1",
    small_preview_image_url: "https://cdn.center.app/1/0x55256178aFE74082c4f9aFEF7E40fec949c1b499/382/b85eed23f70a6775d38293f4327bcaf8dd9e506c911d8cfa066d6080b0097d4e.png",
    url:"https://etherscan.io/address/0x1073777134ccc108b9f59bdceb101588d64b6bdb"
  },
  {address: "",
  collection_name: "Very Fun 2",
  token_id: "Example NFT - 2",
  small_preview_image_url: "https://cdn.center.app/v2/1/7ee237f70ce372c508b216f8faeebd438c36cd698d75aff6e801682c9c4a238d/a86298f7334c3f96b2edce8ebb0dba798ae16d22de6810591a78e36bac0998b5.png",
  url:"https://etherscan.io/address/0x1073777134ccc108b9f59bdceb101588d64b6bdb"
},
{address: "",
    collection_name: "Very Fun 3",
    token_id: "Example NFT - 3",
    small_preview_image_url: "https://cdn.center.app/1/0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB/28/1f0d9bf4972ce77517a0f6cda9effa4f2905adbc8bf5faf92f24781161fffe87.png",
    url:"https://etherscan.io/address/0x1073777134ccc108b9f59bdceb101588d64b6bdb"
  },
  {address: "",
  collection_name: "Very Fun 4",
  token_id: "Example NFT - 4",
  small_preview_image_url: "https://cdn.center.app/1/0x1A92f7381B9F03921564a437210bB9396471050C/11/c837204f9f8c833399c970dcee8cdadf6f5e79e8e608253c8888e82dbac7432d.png",
  url:"https://etherscan.io/address/0x1073777134ccc108b9f59bdceb101588d64b6bdb"
}]
    );
    updateapiState("NFTDataSuccess");
  }
  
  useEffect(() => {
    const getData = async () => {
      let resp = await fetch(walletAPICall, requestOptions);
      let json = await resp.json()
      let nftItems = json.items;

      //Needs to abort if theres no data
      if (nftItems == undefined) {
        updateapiState("noData");
        return
      } else{
        updaterenderNFT([])
      }
      updatenftData(nftItems);
      updateapiState("walletSuccess");

    
    }


    let nftTemp = [];
    
    const fetchItem = async (x) => {
      let tempAddress = x.address;
      let tempTokenId = x.tokenId;
      const resp = await fetch(`https://api.center.dev/v1/${network}/${tempAddress}/${tempTokenId}`, requestOptions); 
      const data = await resp.json();
      nftTemp.push(data);
      return data;
    }


    // API Call State Management
    if (apiState=="init") {
      updateapiState("pendingWalletData")
      getData();
    }
    else if (renderNFT && apiState=="walletSuccess" && !nftTempState) {
      setNFTTempState(true);
      Promise.all([nftItems.map(fetchItem)]).then((promisedResults) => {
        updateapiState("NFTDataSuccess");
        promisedResults.length > 0 && promisedResults[0].forEach(resultPromise => {
          resultPromise.then((result) => {
            if(!renderNFT.find((nft) => nft.token_id === result.token_id)){
              renderNFT.push(result) 
              updaterenderNFT(renderNFT);
              forceUpdate();
            }
          })
        })
      });
    }
    else if (apiState=="noData") {
      console.log("No Data");
    }
  }, [requestOptions, walletAPICall]);


// State and logging for selecting individual NFTs
const [choice, setChoice] = useState();
useEffect(() => {
  localStorage.setItem('choice', JSON.stringify(choice));
}, [choice])

// Function for unselecting everything else whenever users select NFT
const select = (item) => {
  for (let i=0;i<renderNFT.length;i++) {
    renderNFT[i].selection = false;
    //filteredNFTs[i] = renderNFT[i]
  };  
  item.selection = !item.selection;
  setChoice(item);
  }
  
const[filteredNFTs, setFilteredNFTs] = useState(renderNFT)
const[searchWord, setSearchWord] = useState("")

  //Filter function
  const NFTSearch = () => {
    //everytime a letter is typed into the search bar
    const handleFilter = (event) =>{
      const newsearchWord = event.target.value
      setSearchWord(newsearchWord);
      //search bar filtering
      const newFilter=renderNFT.filter((value)=>{ 
        return value.collection_name.toLowerCase().includes(newsearchWord.toLowerCase());
      });
      setFilteredNFTs(newFilter)
    }
  return(
    <div>
    <div style={{marginBottom: 25,  color: '#FFFFFF',}}>
    <SearchOutlined style={{width: "5%", fontSize: 22, }} />
        <Input 
        style={{ width: "95%", color: '#FFFFFF',  borderColor:'#FFFFFF'  }}
        type="text" 
        placeholder="Search Your NFTs"
        value={searchWord}
       onChange={handleFilter}
       autoFocus
        />


    </div>
</div>  )
}


if (apiState === "NFTDataSuccess") {
  return (
      <React.Fragment>
      {addressState ? "" : <Alert message="Demo Mode Active" description="Connect Your Wallet to Access Your NFTs" type="success" />}
      <Divider orientation="left" >Select NFT to Sell  </Divider>
      <div style={{textAlign: "left"}}>
      <NFTSearch> </NFTSearch>
      </div>
      <div style={{ height: 300, overflowX: 'hidden', overflowY: 'auto'}}>
        <List
                grid={{
                  gutter: 16,
                  column: 4,
                }}
            dataSource={searchWord== "" ? renderNFT :filteredNFTs} //display everything or search bar
            renderItem={(item) => (
              <List.Item onClick={()=> select(item)}>
              <NFTcard 
                cardData={item}
              /> 
              </List.Item>
            
            )}
       
          />
      </div>         
   </React.Fragment>
  );}
  else {
    return (
      <React.Fragment>
      <Divider orientation="left">Select NFT to Sell</Divider>      
    
      
    
    <Button type="dashed" onClick={() => {
                    console.log("Hold your horses, hotshot ;) We're working on it");
                    }}>Loading NFT's...</Button>
   </React.Fragment>
  );
}
});




