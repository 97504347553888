import React, { useState, useEffect } from "react";
import { Badge, Button, Card, List, Typography, notification } from "antd";
import { Transactor, Web3ModalSetup } from "../helpers";
import {
  ClockCircleOutlined
} from "@ant-design/icons";

const IMG_PX = "150px"
const BODY_HEIGHT = "260px"
const BODY_PAD = "18px"

/**
  ~ What it does? ~

  Displays an individual Handshake

  ~ How can I use? ~
  UPDATE LATER
  <Events
    contracts={readContracts}
    contractName="YourContract"
    eventName="SetPurpose"
    localProvider={localProvider}
    mainnetProvider={mainnetProvider}
    startBlock={1}
  />
  Handshake({ contracts, contractName, eventName, localProvider, mainnetProvider, startBlock })
**/

export default function HandshakeCardBuyer(props) {
  const { Text } = Typography;
  const { Meta } = Card;
  const { ethers } = require("ethers");
    
  // Get ensProvider from props
  const { ensProvider } = props;

  // Get buyer address from props
  let buyer = props.address;

  //Seller Display Address State Setter
  const [sellerDisplay, setSellerDisplay] = useState(props.data.Seller);

  // Import stuff for the Handshake Acceptance
  const tx = Transactor(props.userSigner, props.gasPrice);
  const writeContracts=props.writeContracts;
  

  var labelId = "Pending";
  var colorState = "blue";
  if (props.data.Status === "Pending") {
    labelId = "Awaiting Your Approval";
    // EDIT COLORS AS WELL
    //BLUE
    } else if (props.data.Status === "Accepted") {
    labelId = "Accepted";
    colorState = "green";
    // Green
    } else if (props.data.Status === "Cancelled") {
      labelId = "Cancelled";
      colorState = "grey";} 
    else {
    labelId = "Error";
    colorState = "red";
    // GREY
    }
  
  
  console.log(props.data.Price);
  // Create Seller Link
  const sellerLink = "https://etherscan.io/address/"+props.data.Seller;
  // Convert price back to ETH
  let priceString = ethers.BigNumber.from(props.data.Price.toString()); //REMOVE TOSTRING WHEN DB IS FIXED
  const price = ethers.utils.formatEther(priceString);

  // Get Time Remaining on Handshake
  let HSTime = new Date(props.data.DateTime);
  let now = new Date();
  let timeDiff = now - HSTime;
  let minutesLeft = Math.ceil(timeDiff / (1000 * 60));

  if (minutesLeft >= 60) {
    if (labelId != "Accepted") {
    labelId = "Handshake Expired";
    minutesLeft=0; //No Time Left 
    colorState = "gray";         
    }
    else {
      minutesLeft=0; //No Time Left
    }
  }
  else if (timeDiff < 30000) {
    minutesLeft = 60 - minutesLeft;
    labelId = "Confirming - Refresh in 10 seconds";
    colorState = "grey"; 
  }
  else {
    minutesLeft = 60 - minutesLeft;
  }
  /// End Time Remaining on Handshake -------------------------------------------------
  

// NEW ACCEPT HANDSHAKE FUNCTION - This allows buyer to accept Handshake
// It takes index of transaction and payment value as inputs 
// These will be autofilled in MVP from reading contract subgraph 
const acceptNew = async () => {
  //
  if (buyer) {
  const setIndex = props.data.TransactionID.toString(); //This is the index of the transaction grabbed from AWS, REMOVE toString() when AWS is fixed
  const priceAccept = props.data.Price.toString(); // This is the price of the handshake grabbed from AWS, REMOVE toString() when AWS DB returns a string 
  
  const result = tx(
    writeContracts &&
      writeContracts.BasicSale &&
      writeContracts.BasicSale.buyInit(setIndex, {value:priceAccept}).then(()=>{
        window.location.href = "/PendingSales"
      }),
    update => {
      // console.log("📡 Transaction Update:", update);
    },
  );
  }
  else if (!buyer) {
    notification.info({
      message: `You're Clearly a Professional!`,
      description:
        'Please Connect your Wallet to Accept a Real Handshake',
    });
    return
}
}

// Update AWS State for card to reflect new status (Will confirm with chain later)

var callStateAPI = async () => {
  if (buyer) {
  let nftSeller = props.data.Seller; //Define Seller for API Call
  let txIndex = props.data.TransactionID; //Define Transaction Index for API Call

  // instantiate a headers object
  var myHeaders = new Headers();
  // add content type header to object
  myHeaders.append("Content-Type", "application/json");
  // using built in JSON utility package turn object to string and store in a variable
  var raw = JSON.stringify(
    {"nftSeller":nftSeller,
      "TransactionID":txIndex, //Need to get this from the blockchain
      "txStatus":"Accepted" //Soft Confirm - Will confirm with chain later in AWS
  });

  // create a JSON object with parameters for API call and store in a variable
  var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
  };
  // make API call with parameters and use promises to get response
  await fetch("https://omu0yb846i.execute-api.us-east-1.amazonaws.com/prod/txStatus", requestOptions)
  .then(response => response.text())
  // .then(result => alert(JSON.parse(result).body))
  // .catch(error => console.log('error', error));
}
}

// Use ENS Address for Display Purposes (don't change the API or SaleInit submission Call)
 
var displaySellerENS = async () => {
  let result = ensProvider && await ensProvider.lookupAddress(props.data.Seller);
  if (result!=null) {
    setSellerDisplay(result);
  }
}

// Make RPC call to check if ENS
useEffect(()=> {displaySellerENS()}, [ensProvider])

//Set DateTime for Card Display
var dateTime = new Date(props.data.DateTime).toLocaleString();

return (
  <>
  <Badge.Ribbon text={labelId} placement="start" color={colorState}>
    <Card
    bodyStyle={{height: BODY_HEIGHT, padding: BODY_PAD, overflow: 'hidden'}}
    cover={
      <img
      alt="NFT"
      src={props.data.ImageURL}
      height='180px' width={IMG_PX}
    />
    }
      actions={(labelId == "Awaiting Your Approval") && [
        <>
        <Button type="primary" onClick={
          ()=>{
            callStateAPI();
            acceptNew();
          }} 
        style={{ background: "green", borderColor: "green"}}>Accept Handshake</Button>
        </>
      ]}
      >
          <Text strong><a style={{overflowWrap: 'break-word'} }
          href={props.data.NFTURL} target="_blank">
          {props.data.CollectionTitle}
          </a> - {props.data.TokenID}</Text>
          <Meta
          description={<List
          size="small"
          itemLayout="vertical"
        >
            <List.Item><Text strong>Seller: <a href={sellerLink} target="_blank">{sellerDisplay}</a></Text> </List.Item>
            <List.Item><Text strong>Price: {price} ETH</Text> </List.Item>
            <List.Item><Text strong> <ClockCircleOutlined />{minutesLeft ? <Text strong> Time Left: {minutesLeft} Minutes</Text>: <Text strong> Date Created: {dateTime}</Text>}</Text> </List.Item>                
            </List>} 
          />        
            </Card>
          </Badge.Ribbon>
  </>

);
}





