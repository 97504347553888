import { Button, Popover } from "antd";
import React, { useState } from "react";
import HelpPopoverForm from "./HelpPopoverForm";
// import emailjs from '@emailjs/browser';
// changed value={address} to address={address}


/** 
  ~ What it does? ~

  Displays a Help modal which sends an email to VF Protocol HQ

  ~ How can I use? ~

  <HelpPopover
    Example={Example}
    
  />

**/


export default function HelpPopover(props) {

  // Not sure if we actually need this
  // Function for logging interaction w "Need Help?" button
// function sendEmail() {
//   const emailVars = {
//       dateTime: Date(Date.now()).toString(),
//       issueURL: window.location.href
//   };

//   emailjs.send("service_tprcqsf", "template_lojdgcp", emailVars, "01KJO-QDJo4-Wm8z8")
//     .then((result) => {
//         console.log(result.text);
//     }, (error) => {
//         console.log(error.text);
//     });
// };
// Add this back into the Need Help Button if desired 
// onClick={() => sendEmail()}


const [open, setOpen] = useState(false);

const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
};  

  return (
    <Popover
        content={
            <HelpPopoverForm/>}
        title="What Went Wrong?"
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
        
        placement = "leftBottom"
        >
            <Button type="primary" shape="round">Need Help?</Button>
        </Popover>
  );
}
