import { Button, Form, Input, message } from "antd";
import React, { useState, useRef } from "react";
import emailjs from '@emailjs/browser';


// changed value={address} to address={address}


/** 
  ~ What it does? ~

  Displays a Help modal which sends an email to VF Protocol HQ

  ~ How can I use? ~

  <HelpPopoverForm
    Example={Example}
    
  />

**/


export default function HelpPopoverForm(props) {
    
  function sendEmail(e) {
    //Send email to VF Protocol HQ
        emailjs.send("service_tprcqsf", "template_w02mhqu", e, "01KJO-QDJo4-Wm8z8")
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
    
    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState("optional");
    const {TextArea} = Input; 

    const onRequiredTypeChange = ({ requiredMarkValue }) => {
      setRequiredMarkType(requiredMarkValue);
    };
  
    return (
      <Form 
      form={form}
      name="emailjs"
      onFinish={(e) => {sendEmail(e);
        form.resetFields();
        message.success('Submitted Successfully!');}}
        layout="vertical"
        initialValues={{
          requiredMarkValue: requiredMark,
          dateTime: Date(Date.now()).toString(),
          issueURL: window.location.href
        }}
        onValuesChange={onRequiredTypeChange}
        requiredMark={requiredMark}
      >
        <Form.Item label="" name="issueMessage" required>
          <TextArea rows={4} placeholder="How we can help?" maxLength={570}/>
        </Form.Item>
        <Form.Item label="Your Email" name="reply_to">
          <Input placeholder="FordPrefect@Megadodo.com" />
        </Form.Item>
        <Form.Item hidden name="dateTime">
            <Input type="hidden" />
        </Form.Item>
        <Form.Item hidden name="issueURL">
            <Input type="hidden" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" block htmlType="submit">Submit</Button>
        </Form.Item>
        
      </Form>
    );
  };

