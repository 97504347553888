import React from 'react';
import 'antd/dist/antd.min.css'
import { EllipsisOutlined, SettingFilled, DownCircleFilled, CheckCircleOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Space, Typography } from 'antd';
import { useThemeSwitcher } from "react-css-theme-switcher";
const { Text, Link } = Typography;
    




export default function SettingsDropdown(props) {
const address=props.address;
const logoutOfWeb3Modal=props.logoutOfWeb3Modal;

const settingMenu = () => {
  return (
    <div 
    style={
  {display: 'block', marginLeft: 8, float: "left" }
      
  }>
      <Menu
        style={{ width: 180 }}
        mode="inline"
      >
          <Menu.Item key="0"><a href="https://www.vfprotocol.com/Withdraw"></a><Text style={{ fontWeight: "bold"}}>Withdraw Funds</Text></Menu.Item>
          <Menu.Divider />
          <Menu.Item key="1"><a  href="https://www.vfprotocol.com/About"></a><Text style={{ fontWeight: "bold"}}>About VF Protocol</Text></Menu.Item>
          <Menu.Divider />
          <Menu.Item key="2"><a target="_blank" href="https://docsend.com/view/ytxd9a9kua95f8ms"></a><Text style={{ fontWeight: "bold"}}>Litepaper</Text></Menu.Item>
          <Menu.Divider />
          <Menu.Item key="3"><a target="_blank" href="https://etherscan.io/address/0x1073777134ccc108b9f59bdceb101588d64b6bdb#code"></a><Text style={{ fontWeight: "bold"}}>Verified Contract <CheckCircleOutlined /></Text></Menu.Item>
          <Menu.Divider />
          <Menu.Item key="4"><a target="_blank" href="https://linktr.ee/vfprotocol"></a><Text style={{ fontWeight: "bold"}}>Linktree</Text></Menu.Item>
          <Menu.Divider />
          {address ? (
          <Menu.Item key="5" onClick={logoutOfWeb3Modal}><Text style={{ fontWeight: "bold"}}>Disconnect Wallet</Text></Menu.Item>) : null} 
      </Menu>
    </div>
  );
}

  const { currentTheme } = useThemeSwitcher();
  
  
  return (
    <Dropdown 
      overlay={settingMenu} 
      trigger={["click"]} 
      style={{ verticalAlign: "top", marginTop: 8, fontSize: '100px', color: '#2caad9'}}
    >
      <Button 
      style={{ verticalAlign: "top", marginLeft: 15, marginTop: 1}}
      shape="round"
      size="large">
        <Text style={{ verticalAlign: "top", fontWeight: "bold"}}>More</Text> <DownCircleFilled />
      </Button>
    </Dropdown>
  );
  };
