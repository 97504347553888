import React, {useState, useEffect} from "react";
import { Badge, Button, Card, List, Typography } from "antd";
import { useContractReader, } from "eth-hooks";
/**
  ~ What it does? ~

  Displays an individual Handshake

  ~ How can I use? ~
  UPDATE LATER
  <Events
    contracts={readContracts}
    contractName="YourContract"
    eventName="SetPurpose"
    localProvider={localProvider}
    mainnetProvider={mainnetProvider}
    startBlock={1}
  />
  Handshake({ contracts, contractName, eventName, localProvider, mainnetProvider, startBlock })
**/

export default function NFTConfirmationCard(props) {
const { ethers } = require("ethers");
const { ensProvider } = props;

//Local Storage Cleaning for Price
try {JSON.parse(localStorage.getItem('dealPrice')).toString()} catch (e) {localStorage.setItem("dealPrice", 0.0001)}



// Get Block Number - Might not be 100% accurate, but only needs to be close enough
// Will get stored in AWS DynamoDB so you can use it later to find most recent SaleInit Event
let approxblockNum = props.blockNum;
let approxBlockNumString = approxblockNum.toString(); //convert to string
// Get seller address from props
let seller = props.address;

const { Title } = Typography;
const { Meta } = Card;
const labelId = "Awaiting Your Confirmation"
const jsonData = JSON.parse(localStorage.getItem('choice')); //Retrieve Handshake data from localStorage

const data = 
  {   CollectionAddress: jsonData.address,
      NFTURL: jsonData.url,
      ImageURL: jsonData.small_preview_image_url,
      Title: jsonData.collection_name,
      Tokenid: jsonData.token_id,
      Buyer: JSON.parse(localStorage.getItem('buyer')),
    Price: JSON.parse(localStorage.getItem('dealPrice')).toString(),
    TimeLeft: "60 Minutes"
  }
const buyerLink = "https://etherscan.io/address/"+data.Buyer;

//Buyer Display Address State Setter
const [buyerDisplay, setBuyerDisplay] = useState(data.Buyer);




// API Call to record transaction in AWS
var callAWSAPI = async (nftSeller,txIndex, collectionTitle, nftURL, imageURL, collectionAddress,TokenID,nftBuyer,nftPrice,approxblockNum) => {
  
  // instantiate a headers object
  var myHeaders = new Headers();
  // add content type header to object
  myHeaders.append("Content-Type", "application/json");
  // using built in JSON utility package turn object to string and store in a variable
  var raw = JSON.stringify(
    {"nftSeller":nftSeller,
      "TransactionID":txIndex, //Need to get this from the blockchain
      "collectionTitle":collectionTitle,
      "nftURL":nftURL,
      "imageURL":imageURL,
      "nftCollectionAddress":collectionAddress,
  "nftTokenID":TokenID,
  "nftBuyer":nftBuyer,
  "nftPrice":nftPrice,
  "txStatus":"Pending", //All tx are pending until they are confirmed by blockchain call
  "approxBlockNum":approxblockNum});

  // create a JSON object with parameters for API call and store in a variable
  var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
  };
  // make API call with parameters and use promises to get response
  await fetch("https://omu0yb846i.execute-api.us-east-1.amazonaws.com/prod", requestOptions)
  .then(response => response.text())

}


// Load Latest Index from VFP Contract IMPORTANT
var indexFromContract = useContractReader(props.readContracts, "BasicSale", "index");

if (indexFromContract > -1) {
  var handshakeIndexString = indexFromContract.toString();
}


// Use ENS Address for Display Purposes (don't change the API or SaleInit submission Call)
 
var displayBuyerENS = async () => {
    let result = await ensProvider.lookupAddress(data.Buyer);
    if (result!=null) {
      setBuyerDisplay(result);
    }
}

// Make RPC call to check if ENS
useEffect(()=> {displayBuyerENS()}, [ensProvider])


//Convert before sending to AWS & EVM
let gweiPrice = ethers.utils.parseEther(data.Price);
// Convert to gweiPrice to string
let gweiPriceString = gweiPrice.toString();

  return (
      <>
      <Badge.Ribbon text={labelId} placement="start" color="grey">
        <Card
          cover={
            <img
              alt="NFT"
              src={data.ImageURL}
            />
          }
          actions={[
            <>
            {/* <a href="/PendingSales"> */}
              <Button type="primary" onClick={
                ()=>{callAWSAPI(seller, handshakeIndexString, data.Title, data.NFTURL, data.ImageURL, data.CollectionAddress, data.Tokenid, data.Buyer, gweiPriceString, approxBlockNumString); //Call API to record transaction                
                props.submitHandshake(); //Calls function in App.js to submit Handshake to VFP Controller
              }} 
              style={{ background: "green", borderColor: "green"}}>
                Submit Handshake</Button>
                {/* </a> */}

            <Button onClick={()=>{window.location.href='/'}}>Cancel Handshake</Button>
            
            </>
          ]}
        >
          <Meta
            title={<Title level={3}><a href={data.NFTURL} target="_blank">{data.Title}</a> - #{data.Tokenid}</Title>}
            description={<List
                        size="small"
                        itemLayout="vertical"
                        >
                          <List.Item />
                          <List.Item><Title level={3} strong>Buyer: <a href={buyerLink} target="_blank" rel="noopener noreferrer">{buyerDisplay}</a></Title> </List.Item>
                          <List.Item><Title level={3} strong>Price: {data.Price} ETH</Title> </List.Item>                
                        </List>}
              />        
          </Card>
      </Badge.Ribbon>
    </>
  );
}







