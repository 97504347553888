import React, {useState} from "react";
import { Avatar, Badge, Button, Card, Divider, Empty, List, Row, Col, Typography,  } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons"
import HandshakeCardBuyer from "./HandshakeCardBuyer";
import { Token } from "graphql";

/**
  ~ What it does? ~

  Grid layout for Inbound Handshakes (for user to buy)

  ~ How can I use? ~
  UPDATE LATER
  <Events
    contracts={readContracts}
    contractName="YourContract"
    eventName="SetPurpose"
    localProvider={localProvider}
    mainnetProvider={mainnetProvider}
    startBlock={1}
  />
  Handshake({ contracts, contractName, eventName, localProvider, mainnetProvider, startBlock })
**/


export default function HCardBuyerList(props) {
  // Get ensProvider from props
  const { ensProvider } = props;

  // Get buyer address from props
  let buyer = props.address;
  // Set API call root url
  let apiCall = "https://omu0yb846i.execute-api.us-east-1.amazonaws.com/prod/buyer/"+buyer;
  var nftData = [];

  // Initialize state for key variables
  const [apiState, updateapiState] = useState("loading"); // Set initial API state
  const [addressState, setAddressState] = useState(false); // Set initial address Bool
  const [nftCompData, setNftCompData] = useState(); // Set initial NFT data
  const [showAllShakes, setShowAllShakes] = useState(false); // Set initial show/hide all state


  // API Call to get transactions from AWS
  var APIGetCall = async () => {
    // instantiate a headers object
    var myHeaders = new Headers();
    // add content type header to object
    myHeaders.append("Content-Type", "application/json");
    // using built in JSON utility package turn object to string and store in a variable
    var raw = JSON.stringify(); //Leave Blank because its a GET call
    // create a JSON object with parameters for API call and store in a variable
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    // make API call with parameters and use promises to get response
    let result = await fetch(apiCall, requestOptions)
      .then((response) => { //THIS IS HOW YOU ACCESS THE RESPONSE DONT DELETE
        return response.json()})

    nftData = result.Items; 
    setNftCompData(nftData);
    updateapiState("loaded");
    return result.Items;  
  }

// Trigger API Call on buyer address loading properly
if (buyer && buyer.length == 42 && !addressState) { 
  setAddressState(true);
  APIGetCall(); 
  updateapiState("Pending");
}


  // Get Time Remaining on Handshake
const handshakeIsExpired = (dateTime) => {
  let HSTime = new Date(dateTime);
  let now = new Date();
  let timeDiff = now - HSTime;
  let minutesLeft = Math.ceil(timeDiff / (1000 * 60));
  return minutesLeft >= 60
}


const handshakeGrid = (nftGridData) => {
  return    <List
  rowKey = {"data.TransactionID"} // Idk why but this doesn't work
  grid={{
  gutter: 16,
  column: 3,
  }}
  dataSource={nftGridData}
  renderItem={(item) => (

  <List.Item>
    <HandshakeCardBuyer 
    data={item}
    userSigner={props.userSigner} 
    gasPrice={props.gasPrice}
    writeContracts={props.writeContracts}
    ensProvider={ensProvider}
  /> 
    </List.Item>
  )}
    />
}

if (apiState == "loaded") {
  return (
      <>
    <Divider orientation="left">Handshakes in Progress</Divider>
    {handshakeGrid(nftCompData.filter(i => i.Status == 'Pending' && !handshakeIsExpired(i.DateTime)))}
    <Divider orientation="left">
      <span style = {{cursor: 'pointer'}} 
      onClick={() => {setShowAllShakes(!showAllShakes)}}>
        <Button>{showAllShakes ? <CaretDownOutlined/>: <CaretUpOutlined/>} View All Received Handshakes</Button></span>
    </Divider>
    {showAllShakes && handshakeGrid(nftCompData.filter(i => 
      i.Status !== 'Pending' || (i.Status == 'Pending' && handshakeIsExpired(i.DateTime))
    ))}
    
   </>
  );
}
else {
    return (
        <>
        <Divider orientation="left">Loading Received Handshakes...</Divider>      
      
        
      
      <Button type="dashed" onClick={() => {
                        APIGetCall(buyer); 
                      }}>Loading Handshakes...</Button>
     </>
    );
  }
}
 


