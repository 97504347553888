import React, {useState, useEffect} from "react";
import { Badge, Button, Card, List, Typography, notification } from "antd";
import { Transactor } from "../helpers";
import {
  ClockCircleOutlined
} from "@ant-design/icons";

const IMG_PX = "150px"
const BODY_HEIGHT = "260px"
const BODY_PAD = "18px"

/**
  ~ What it does? ~

  Displays an individual Outbound (Seller) Handshake

  ~ How can I use? ~
  UPDATE LATER
  <Events
    contracts={readContracts}
    contractName="YourContract"
    eventName="SetPurpose"
    localProvider={localProvider}
    mainnetProvider={mainnetProvider}
    startBlock={1}
  />
  Handshake({ contracts, contractName, eventName, localProvider, mainnetProvider, startBlock })
**/

export default function HandshakeCardSeller(props) {
const { Text } = Typography;
const { Meta } = Card;
const { ethers } = require("ethers");

// Get ensProvider from props
const { ensProvider } = props;

//Buyer Display Address State Setter
const [buyerDisplay, setBuyerDisplay] = useState(props.data.Buyer);

// Get buyer address from props
let seller = props.address;

// Import stuff for the Handshake Cancel
  const tx = Transactor(props.userSigner, props.gasPrice);
  const writeContracts=props.writeContracts;



// LabelID State Setter
var labelId = "Pending";
var colorState = "blue";
if (props.data.Status === "Pending") {
labelId = "Awaiting Buyer Acceptance"
// EDIT COLORS AS WELL
//BLUE
} else if (props.data.Status === "Accepted") {
labelId = "Accepted by Buyer";
colorState = "green";
// Green
} else if (props.data.Status === "Cancelled") {
  labelId = "Cancelled";
  colorState = "grey";}
else {
labelId = "Error";
// Red
colorState = "red";
}



// Create Buyer Link
const buyerLink = "https://etherscan.io/address/"+props.data.Buyer;
// Convert price back to ETH
let priceString = ethers.BigNumber.from(props.data.Price.toString());
const price = ethers.utils.formatEther(priceString);

// Get Time Remaining on Handshake
let HSTime = new Date(props.data.DateTime);
let now = new Date();
let timeDiff = now - HSTime;
let minutesLeft = Math.ceil(timeDiff / (1000 * 60));

if (minutesLeft >= 60) {
  if (labelId != "Accepted by Buyer") {
  labelId = "Handshake Expired";
  minutesLeft=0; //No Time Left 
  colorState = "gray";         
  }
  else {
    minutesLeft=0; //No Time Left
  }
}
else {
  minutesLeft = 60 - minutesLeft;
}
/// End Time Remaining on Handshake -------------------------------------------------


// NEW CANCEL HANDSHAKE FUNCTION - This allows buyer to cancel Handshake
// It takes index of transaction as input 
// These will be autofilled in MVP from Card data (from AWS) 
const cancelNew = async () => {
  //
  if (seller) {
  const setIndex = props.data.TransactionID.toString(); //This is the index of the transaction grabbed from AWS, REMOVE toString() when AWS is fixed
    
  const result = tx(
    writeContracts &&
      writeContracts.BasicSale &&
      writeContracts.BasicSale.cancel(setIndex).then(()=>{
        window.location.href = "/PendingSales"
      }),
    update => {
      console.log("📡 Transaction Update:", update);
    },
  );
  }  else if (!seller) {
    notification.info({
      message: `You're Clearly a Professional!`,
      description:
        'Please Connect your Wallet to Cancel a Real Handshake',
    });
    return
}

}

// Update AWS State for card to reflect new status (Will confirm with chain later)
var callStateAPI = async () => {
  if (seller){
  let nftSeller = props.data.Seller; //Define Seller for API Call
  let txIndex = props.data.TransactionID; //Define Transaction Index for API Call

  // instantiate a headers object
  var myHeaders = new Headers();
  // add content type header to object
  myHeaders.append("Content-Type", "application/json");
  // using built in JSON utility package turn object to string and store in a variable
  var raw = JSON.stringify(
    {"nftSeller":nftSeller,
      "TransactionID":txIndex, //Need to get this from the blockchain
      "txStatus":"Cancelled" //Soft Confirm - Will confirm with chain later in AWS
  });
  // create a JSON object with parameters for API call and store in a variable
  var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
  };
  // make API call with parameters and use promises to get response
  await fetch("https://omu0yb846i.execute-api.us-east-1.amazonaws.com/prod/txStatus", requestOptions)
  .then(response => response.text())
}}



// Use ENS Address for Display Purposes (don't change the API or SaleInit submission Call)
 
var displayBuyerENS = async () => {
  let result = ensProvider && await ensProvider.lookupAddress(props.data.Buyer);
  if (result!=null) {
    setBuyerDisplay(result);
  }
}

// Make RPC call to check if ENS
useEffect(()=> {displayBuyerENS()}, [ensProvider])

//Set DateTime for Card Display
var dateTime = new Date(props.data.DateTime).toLocaleString();

return (
  <>
  <Badge.Ribbon text={labelId} placement="start" color={colorState}>
    <Card
    bodyStyle={{height: BODY_HEIGHT, padding: BODY_PAD, overflow: 'hidden'}}
    cover={
      <img
      alt="NFT"
      src={props.data.ImageURL}
      height="180px" width={IMG_PX}
    />
    }
      actions={(labelId == "Awaiting Buyer Acceptance") && [
        <>
        <Button type="primary" onClick={()=>{
          callStateAPI();
          cancelNew();
        }
        } style={{ background: "Red", borderColor: "Black"}}>Cancel Handshake</Button>
        </>
      ]}
      >
          <Text strong><a style={{overflowWrap: 'break-word'} }
          href={props.data.NFTURL} target="_blank">
          {props.data.CollectionTitle}
          </a> - {props.data.TokenID}</Text>
          <Meta
          description={<List
                      size="small"
                      itemLayout="vertical"
                      >
                        <List.Item><Text strong>Buyer: <a href={buyerLink} target="_blank">{buyerDisplay}</a></Text> </List.Item>
                        <List.Item><Text strong>Price: {price} ETH</Text> </List.Item>
                        <List.Item><Text strong> <ClockCircleOutlined />{minutesLeft ? <Text strong> Time Left: {minutesLeft} Minutes</Text>: <Text strong> Date Created: {dateTime}</Text>}</Text> </List.Item>                
                      </List>}
            />        
        </Card>
    </Badge.Ribbon>
  </>
);


}




