import React from "react";
import { Badge, Card, Typography } from "antd";
// import { useEventListener } from "eth-hooks/events/useEventListener";
// console.log("RENDERING");
const IMG_PX = "150px"
const BODY_HEIGHT = "100px"
const BODY_PAD = "18px"

/**
  ~ What it does? ~

  Displays an individual NFT Card

  ~ How can I use? ~
  UPDATE LATER
  <Events
    contracts={readContracts}
    contractName="YourContract"
    eventName="SetPurpose"
    localProvider={localProvider}
    mainnetProvider={mainnetProvider}
    startBlock={1}
  />
  Handshake({ contracts, contractName, eventName, localProvider, mainnetProvider, startBlock })
**/

export default function NFTcard(props) {
  const {cardData} = props; 
  const { Text, Title } = Typography;
  const { Meta } = Card;
  const labelId = "Selected"
  const collectionLink = cardData.url;
  // const demoImg = "https://cdn.center.app/1/0x79FCDEF22feeD20eDDacbB2587640e45491b757f/123/931be9a4a1f7512c9cf3a1ecb4ad7fca5bed6efaf5cdec7cd1425d223072be98.png"; 
  // Fox = "https://cdn.center.app/1/0x55256178aFE74082c4f9aFEF7E40fec949c1b499/382/b85eed23f70a6775d38293f4327bcaf8dd9e506c911d8cfa066d6080b0097d4e.png";
  if (cardData.small_preview_image_url == null) {
    cardData.small_preview_image_url= "https://gateway.pinata.cloud/ipfs/QmbCHVteq4iW639xgSYKH89XBymWziuvbxwGxxc82FinMf";
  }

  const card = (cardData) => {
    return (
      <Card
      //onClick={handleClick}
      style={{
        // backgroundColor: cardData.selection===true ? '#2caad9' : '',
        border: cardData.selection===true ? 'solid 2px #2caad9' : '',
      }}

      hoverable
      bodyStyle={{height: BODY_HEIGHT, padding: BODY_PAD, overflow: 'hidden'
    }}
      cover={
        <img
        height={IMG_PX} width={IMG_PX}
        alt="NFT"
        // src = {demoImg}
        src={cardData.small_preview_image_url}
      />
      }
      >
          <Text strong><a style={{overflowWrap: 'break-word', color: '#FFFFFF'} }
          href={collectionLink} target="_blank">
          {cardData.collection_name}
          </a> - {cardData.token_id}</Text>
        </Card>
    )
  }

  if (cardData.selection===true) {
    return (
      <>
      <Badge.Ribbon text={labelId} placement="start">
        {card(cardData)}
      </Badge.Ribbon>
      </>
    );
  } else {
    return (
      <>{card(cardData)}</>
    );
  }

}
