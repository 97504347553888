import {Alert, Badge, Button, Col, Divider, Image, InputNumber, Menu, Modal, notification, Typography,Row } from "antd";
import "antd/dist/antd.css";
import {
  useBlockNumber,
  useContractLoader,
  useContractReader,
  useGasPrice,
  useUserProviderAndSigner,
} from "eth-hooks";
import { useExchangeEthPrice } from "eth-hooks/dapps/dex";
import React, { useCallback, useEffect, useState } from "react";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import "./App.css";
import {
  Account,
  AcceptedHandshakes,
  ActiveAccepts,
  Address,
  AddressInput,
  Header,
  NetworkDisplay,
  NetworkSwitch,
  HCardBuyerList,
  HCardSellerList,
  NFTcardGrid,
  NFTConfirmationCard,
  WithdrawBalance,
  HelpPopover,
  SettingsDropdown
} from "./components";
import {
  TwitterOutlined
} from '@ant-design/icons';
import linktreeIcon from './linktreeIcon.svg';
import telegramIcon from './telegramIcon.svg';
// import { NETWORKS, ALCHEMY_KEY } from "./constantsTemplate";
import { NETWORKS, ALCHEMY_KEY } from "./constants"; //constants w/ Production API Keys
import externalContracts from "./contracts/external_contracts";
// contracts
import deployedContracts from "./contracts/hardhat_contracts.json";
import ERC721ABI from "./contracts/ABI/ERC721.json";
import { Transactor, Web3ModalSetup } from "./helpers";
import { useStaticJsonRPC } from "./hooks";
import LogRocket from 'logrocket';
import {Helmet} from "react-helmet";
LogRocket.init('y3fljr/vf-protocol-v0-beta', {release: '0.0.1'}); //Add in session level tracking for wallets later

const {Text} = Typography;
const { ethers } = require("ethers");


/// 📡 What chain are your contracts deployed to?
const initialNetwork = NETWORKS.mainnet; // <------- select your target frontend network (localhost, rinkeby, xdai, mainnet)

// 😬 Sorry for all the console logging
const DEBUG = false; // <------- set to false to turn off console logging
const NETWORKCHECK = true;
const USE_BURNER_WALLET = false; // toggle burner wallet feature
const USE_NETWORK_SELECTOR = false;

const web3Modal = Web3ModalSetup();


// 🛰 providers
const providers = [
  "https://eth-mainnet.gateway.pokt.network/v1/lb/611156b4a585a20035148406",
  `https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_KEY}`,
  // "https://rpc.scaffoldeth.io:48544",
];

function App(props) {
  // specify all the chains your app is available on. Eg: ['localhost', 'mainnet', ...otherNetworks ]
  // reference './constants.js' for other networks
  const networkOptions = [initialNetwork.name, "mainnet"]; // "mainnet", back later

  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(networkOptions[0]);
  const location = useLocation();


  const targetNetwork = NETWORKS[selectedNetwork];

  // 🔭 block explorer URL
  const blockExplorer = targetNetwork.blockExplorer;

  // load all your providers
  const localProvider = useStaticJsonRPC([
    process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : targetNetwork.rpcUrl,
  ]);
  const mainnetProvider = useStaticJsonRPC(providers);

  if (DEBUG) console.log(`Using ${selectedNetwork} network`);

  // 🛰 providers
  if (DEBUG) console.log("📡 Connecting to Mainnet Ethereum");

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };


  // // If you want to call a function on a new block
  // useOnBlock(mainnetProvider, () => {
  //   console.log(`⛓ A new mainnet block is here: ${mainnetProvider._lastBlockNumber}`);
  // });

  //load google analytics scripts. May need to be frontloaded in order.
	useEffect(() => {
		const gscript = document.createElement("script")
		gscript.async = true;
		gscript.src="https://www.googletagmanager.com/gtag/js?id=G-0ZT3HSFY9S"
		const gscriptTwo = document.createElement("script")
		gscriptTwo.async = true;
		gscriptTwo.append(`
	    window.dataLayer = window.dataLayer || [];
	    function gtag(){dataLayer.push(arguments);}
	    gtag('js', new Date());
	    gtag('config', 'G-0ZT3HSFY9S');
    `)
		document.body.appendChild(gscript)
		document.body.appendChild(gscriptTwo)

		return () => {
			// clean up the gscript when the component in unmounted
			document.body.removeChild(gscript)
			document.body.removeChild(gscriptTwo)
		}
	}, [])

  /* 💵 This hook will get the price of ETH from 🦄 Uniswap: */
  const price = useExchangeEthPrice(targetNetwork, mainnetProvider); //Need to stop this from leaking calls every re-render
  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  const gasPrice = useGasPrice(targetNetwork, "fast");
  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.
  const userProviderAndSigner = useUserProviderAndSigner(injectedProvider, localProvider, USE_BURNER_WALLET);
  const userSigner = userProviderAndSigner.signer;
  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress);
      }
    }
    getAddress();
    
  }, [userSigner]);


  // You can warn the user if you would like them to be on a specific network
  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId =
    userSigner && userSigner.provider && userSigner.provider._network && userSigner.provider._network.chainId;
 
  
  // For more hooks, check out 🔗eth-hooks at: https://www.npmjs.com/package/eth-hooks
  // The transactor wraps transactions and provides notificiations
  const tx = Transactor(userSigner, gasPrice);
  
  // Record Block Number for later use in NFTConfirmationCard for AWS storage
  const blockNum = useBlockNumber(localProvider);

  // const contractConfig = useContractConfig();
  const contractConfig = { deployedContracts: deployedContracts || {}, externalContracts: externalContracts || {} };
  // Load in your local 📝 contract and read a value from it:
  const readContracts = useContractLoader(localProvider, contractConfig);

  // If you want to make 🔐 write transactions to your contracts, use the userSigner:
  const writeContracts = useContractLoader(userSigner, contractConfig, localChainId);


  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", chainId => {
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
      
    });

    provider.on("accountsChanged", () => {
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
      setTimeout(() => {
        window.location.reload();
      }, 1);
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      logoutOfWeb3Modal();
    });
    // eslint-disable-next-line
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]); 

// This call retrieves the BasicSale Controller contract address
const vfprotocolv0 = readContracts?.BasicSale?.address; 

// This call gets the user's live Withdraw balance from the BasicSale Controller contract
const vfpBalance = useContractReader(readContracts, "BasicSale", "balanceOf", [address]);

//
// Set useState key variables to store for confirmation
//

const [buyer, setBuyer] = useState(); // Set the buyer address
const [dealPrice, setPrice] = useState(); // Set the sale price price
const [buttonApprove, setButtonApprove] = useState(true); //Keep Review button on lander grey until all data is set

// Disable Review Button on Lander until NFT selection is made IMPORTANT
var buttonCheck = localStorage.getItem('choice');

if(buttonCheck !== "undefined" && buttonApprove === true && dealPrice>0){
    setButtonApprove(false);
}

// End Review Button Disable

//-------------Start VF Protocol Core Functions ----------------------------------------------
// 1. Approval Function sent to NFT contract to allow BasicSale Controller to transfer NFT
// Approve Function for Selected Token for Transfer by Seller in UX FLOW - WORKS WITH RINKEBY AND MAINNET
const approveNew = async () => {
  
  // Check if Buyer is valid before approving token (save ETH)
  if (ethers.utils.isAddress(buyer) === true && address && buyer !== address) {
    const jsonData = JSON.parse(localStorage.getItem('choice')); //Retrieve Handshake data from localStorage
    const selectNFTAddress = jsonData.address; //Retrieve address
    const selectTokenId = jsonData.token_id; //Retrieve id

  //Create separate instances of NFT contract for reading and writing  
  readContracts.selectNFTContractAddress = new ethers.Contract(selectNFTAddress, ERC721ABI, localProvider);
  writeContracts.selectNFTContractAddress = new ethers.Contract(selectNFTAddress, ERC721ABI, userSigner);
  

  // Does nothing until on correct network and contract is deployed
  const result = tx(
    writeContracts &&
      writeContracts.selectNFTContractAddress &&
      writeContracts.selectNFTContractAddress.approve(vfprotocolv0, selectTokenId).then(()=>{
        window.location.href='/confirm'}), //IMPORTANT BIT -> redirects to confirmation page
       
        update => {
      console.log("📡 Transaction Update:", update);
      if (update && (update.status === "confirmed" || update.status === 1)) {
        
        console.log(" 🍾 Transaction " + update.hash + " finished!");
        console.log(
          " ⛽️ " +
            update.gasUsed +
            "/" +
            (update.gasLimit || update.gas) +
            " @ " +
            parseFloat(update.gasPrice) / 1000000000 +
            " gwei",
        );
      }
    },
  )
  }
  else if (!address) {
    notification.info({
      message: `You're Clearly a Professional!`,
      description:
        'Please Connect your Wallet to Create a Real Handshake or see "How it Works" Below',
    });
    return
  }
  else if (buyer === address) {
    notification.info({
  message: `Buyer Address Cannot be Seller Address 🤷`,
  description:
    'Please check the address and try again',
  });
return
} else {
    notification.warning({
      message: `Invalid Buyer Address 😕`,
      description:
        'Please check the address and try again',
    });
    return
  }
  };

// 2. Submit Handshake - This submits the Handshake to VFProtocolv0 contract
// with Buyer, Price, NFT Contract Address, Token ID as inputs
// These inputs are stored in a mapping in BasicSale 
const submitHandshakeNew = async () => {  
  
  const jsonData = JSON.parse(localStorage.getItem('choice')); //Retrieve Handshake data from localStorage

  const selectBuyer = JSON.parse(localStorage.getItem('buyer')); //Retrieve Buyer address

  //Convert to gwei before sending to EVM
  const selectGweiPrice = ethers.utils.parseEther(JSON.parse(localStorage.getItem('dealPrice')).toString()) // Convert to gwei;
 
  // Read data retrieved from localStorage
  const selectNFTAddress = jsonData.address; //Retrieve address
  const selectTokenId = jsonData.token_id; //Retrieve id

  const result = tx(
    writeContracts &&
      writeContracts.BasicSale &&
      writeContracts.BasicSale.saleInit(selectBuyer, selectGweiPrice, selectNFTAddress, selectTokenId).then(()=>{
        window.location.href='/PendingSales'}), //Redirect to Pending Sales Page - IMPORTANT BIT DON'T DELETE,
    update => {
      console.log("📡 Transaction Update:", update);
    },
    );
  };


// Accept Handshake Function lives in HCardBuyer component
// 3. Accept Handshake - This allows buyer to accept Handshake
// It takes index of transaction and payment value as inputs 

// 4. WITHDRAW FUNCTION - This lets the seller withdraw their funds after a buyer accepts a Handshake.
// They can also check their balance with this widget in the Redeem Page. 

const withdrawFundsNew = async () => {
  if (address) {
  const result = tx(
    writeContracts &&
      writeContracts.BasicSale &&
      writeContracts.BasicSale.withdraw(),
    update => {
      console.log("📡 Transaction Update:", update);
    },
    // alert("Handshake Successfully Shook - Congrats!")
    );
    // if(result) alert("ETH Withdrawn - Congrats!") 
  }
else if (!address) {
  notification.info({
    message: `You're Clearly a Professional!`,
    description:
      'Please Connect your Wallet to check your balance or withdraw your funds',
  });
  return
}

}

//------End of VFProtocolv0 Functions ------------------------------




// Number Form Validation Inputs
const validateNumber = (number) => {
  if (number > 0.0001 ) {
    return {
      validateStatus: 'success',
      errorMsg: null,
    };
  }

  return {
    validateStatus: 'error',
    errorMsg: 'Please enter a number greater than 0.0001 and less than 100000000000',
  };
};

const [number, setNumber] = useState(0);

  const onNumberChange = (value) => {
    setNumber({ ...validateNumber(value), value });

  };

// Function for Add in Help Modal (Create Handshakes page)
const helpModal = () => {
  Modal.success({
    width:'60%',
    content: <Text>
<div style={{position: 'relative', paddingBottom: '40%', height: 0, width: '100%', margin: 'auto'}}><iframe style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '110%'}} src="https://www.youtube.com/embed/4-K2QjUwE6c" title="VF Protocol: How it Works" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>
{/* <div style={{position: 'relative', paddingBottom: '62.5%', height: 0}}><iframe src="https://www.loom.com/embed/e72dbad0c2544fa4a6434d505ae77a39" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}></iframe></div> */}
      <h1>How It Works:</h1>
          <h2>1. 🔎 Select your NFT to sell</h2>
          <h2>2. ✨ Type in your Buyer's ENS and Sale Price</h2>
          <h2>3. 🎉 Click Review Handshake!</h2> 
          </Text>
    ,
  });
};

// API Call for checking if a notification badge is needed for "View and Accept Handshakes" page
// State for calling API
const [apiCalled, setApiCalled] = useState(false); // Set initial address Bool
const [acceptBadge, setAcceptBadge] = useState(0); // Set initial notification badge number
const [demoState, setDemoState] = useState(false); // Set initial demo state

if (address && !apiCalled) {
  setApiCalled(true)
  let apiCall = "https://omu0yb846i.execute-api.us-east-1.amazonaws.com/prod/buyer/"+address;
  let nftData =[];

  // Get Time Remaining on Handshake
  const handshakeIsExpired = (dateTime) => {
    let HSTime = new Date(dateTime);
    let now = new Date();
    let timeDiff = now - HSTime;
    let minutesLeft = Math.ceil(timeDiff / (1000 * 60));
    return minutesLeft >= 60
  }
  
  
  // API Call to get transactions from AWS
  var APIGetCallandCalc = async () => {
    // instantiate a headers object
    var myHeaders = new Headers();
    // add content type header to object
    myHeaders.append("Content-Type", "application/json");
    // using built in JSON utility package turn object to string and store in a variable
    var raw = JSON.stringify(); //Leave Blank because its a GET call
    // create a JSON object with parameters for API call and store in a variable
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    // make API call with parameters and use promises to get response
    let result = await fetch(apiCall, requestOptions)
      .then((response) => { //THIS IS HOW YOU ACCESS THE RESPONSE DONT DELETE
        return response.json()})

    nftData = result.Items; 
    var liveAccepts = nftData.filter(i => i.Status == 'Pending' && !handshakeIsExpired(i.DateTime))
    // console.log(liveAccepts, liveAccepts.length);
    setAcceptBadge(liveAccepts.length);
    // return result.Items;  
  }

  APIGetCallandCalc();
} else if (!address && !demoState) {
  setDemoState(true);
  setAcceptBadge(1);
}


// This is where everything renders
  return (
    <div className="App">
      {/* ✏️ Edit the header component and change the title to your project name */}
      <Helmet>
        <script type="text/javascript"> 
          {`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
          heap.load("696855453");`}
        </script>
        
      </Helmet>
      <Header />
      <NetworkDisplay
        NETWORKCHECK={NETWORKCHECK}
        localChainId={localChainId}
        selectedChainId={selectedChainId}
        targetNetwork={targetNetwork}
        logoutOfWeb3Modal={logoutOfWeb3Modal}
        USE_NETWORK_SELECTOR={USE_NETWORK_SELECTOR}
      />
      
      <Menu style={{ textAlign: "center", marginTop: 30 }} selectedKeys={[location.pathname]} mode="horizontal">
        <Menu.Item key="/">
          <Link to="/"><h2 class="br"><b>Create</b><span class="br"></span></h2><h2><b>Handshakes</b></h2></Link>
        </Menu.Item>
        {/* <Menu.Item key="/PendingOffers">
          <Link to="/PendingOffers"><h2 class="br"><b>Accept</b><span class="br"></span></h2><h2><b>Handshakes</b></h2></Link>
        </Menu.Item> */}
        <Menu.Item key="/PendingSales">
        <Link to="/PendingSales"><h2 class="br"><b>Accept & View<Badge style={{backgroundColor: '#2CAAD9'}} count={acceptBadge} offset={[-5, -20]}/></b><span class="br"></span></h2><h2><b>Handshakes</b></h2></Link>
        </Menu.Item>
        <Menu.Item key="/HowItWorks">
          <Link to="/HowItWorks"><h2 class="br"><b>What is a</b><span class="br"></span></h2><h2><b>Handshake?</b></h2></Link>
        </Menu.Item>
         {/*<Menu.Item key="/Withdraw">
          <Link to="/Withdraw"><h2 class="br"><b>Withdraw</b><span class="br"></span></h2><h2><b>Funds</b></h2></Link>
        </Menu.Item>
        <Menu.Item key="/About">
          <Link to="/About"><h2 class="br"><b>About</b><span class="br"></span></h2><h2><b>VF Protocol</b></h2></Link>
        </Menu.Item> 
        {/* <Menu.Item key="/debug">
          <Link to="/debug">Debug Contracts</Link>
        </Menu.Item> */}
      </Menu>

      <Switch>        
           {/*Step 0 - THIS IS THE CREATE HANDSHAKE LANDER */}
          <Route exact path="/">
        <div style={{ width: '90%',maxWidth: 640, minWidth:620, margin: "auto", marginTop: 32, paddingBottom: 32, }}>
          <NFTcardGrid
          address={address} />
        </div>
        <div style={{ maxWidth: 640, minWidth:620, margin: "auto", marginTop: 32, paddingBottom: 130 }}>
        <AddressInput
                ensProvider={mainnetProvider}
                placeholder="Enter Buyer address or ENS"
                value={buyer}
                onChange={setBuyer}
                />
          <InputNumber
          style={{ width: '100%', color: '#FFFFFF',  borderColor:'#FFFFFF'  }}
          min={0.0001} max={100000000000}
            onChange={
              e => {
              onNumberChange(e);
              setPrice(e);
              localStorage.setItem('dealPrice', JSON.stringify(e));
              
            }}
            placeholder="Set Price in ETH"
          />
            <Button
            type="primary"
            style={{ marginTop: 8 }}
            disabled={buttonApprove}
            shape="round"
            size="large"
            onClick={() => {
              approveNew();
            }}
            
          >
            Review Handshake
          </Button>
          <div>
          <br/>
          <Button onClick={helpModal} shape="round">How It Works</Button>
          </div>
          </div>
          <div>
          <h3><a href="https://twitter.com/vfprotocol" target="blank">🎉 VF Protocol</a> - Made with ❤️ by <a href="https://linktr.ee/camarmstrong" target="blank">Cameron</a></h3>
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: '10px'}}>
          <a href="https://twitter.com/vfprotocol" target="blank"><TwitterOutlined style={{ fontSize: '200%'}} /></a> <Text/>
          <a href="https://t.me/vfprotocol" target="blank" style={{paddingLeft:"25px"}}><img src={telegramIcon} alt="Telegram" width={35} /></a> <Text/>
          <a href="https://linktr.ee/vfprotocol" target="blank" style={{paddingLeft:"25px"}}><img src={linktreeIcon} alt="Linktree" width={35} /></a> </div>
          <div class="fixed-widgets">
          <HelpPopover/>
          </div>
          <br></br>
          <br></br>
          </div>
        </Route>

        {/*Step 1 - This is the confirmation screen - only redirected to, not directly accessible */}        
        <Route exact path="/confirm">        
        <div style={{ width: 340, margin: "auto", marginTop: 32, paddingBottom: 120 }}>
        <Row>
            <Col>
            </Col>
            <Col >
            <NFTConfirmationCard
            address={address}
            ensProvider={mainnetProvider}
            readContracts={readContracts}
            localProvider={localProvider}
            blockNum = {blockNum}
            submitHandshake = {submitHandshakeNew}
            />
            </Col>
            <Col >
            
            </Col>
          </Row>
              </div>
              <br />
          <br />
          <div>
          <h3><a href="https://twitter.com/vfprotocol" target="blank">🎉 VF Protocol</a> - Made with ❤️ by <a href="https://linktr.ee/camarmstrong" target="blank">Cameron</a></h3>
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: '10px'}}>
          <a href="https://twitter.com/vfprotocol" target="blank"><TwitterOutlined style={{ fontSize: '200%'}} /></a> <Text/>
          <a href="https://t.me/vfprotocol" target="blank" style={{paddingLeft:"25px"}}><img src={telegramIcon} alt="Telegram" width={35} /></a> <Text/>
          <a href="https://linktr.ee/vfprotocol" target="blank" style={{paddingLeft:"25px"}}><img src={linktreeIcon} alt="Linktree" width={35} /></a> </div>
          <div class="fixed-widgets">
          <HelpPopover/>
          </div>
          <br></br>
          <br></br>
          </div>
        </Route>

        {/*This is the Pending Offers Page where users can Accept Handshakes*/}
        <Route exact path="/PendingOffers">
        <div style={{ maxWidth: 640, minWidth:620, margin: "auto", marginTop: 32, paddingBottom: 32 }}>
          <HCardBuyerList
          address={address}
          ensProvider={mainnetProvider}
          userSigner={userSigner} 
          gasPrice={gasPrice}
          writeContracts={writeContracts}
          />
          </div>
          <br />
          <br />
          <div>
          <h3><a href="https://twitter.com/vfprotocol" target="blank">🎉 VF Protocol</a> - Made with ❤️ by <a href="https://linktr.ee/camarmstrong" target="blank">Cameron</a></h3>
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: '10px'}}>
          <a href="https://twitter.com/vfprotocol" target="blank"><TwitterOutlined style={{ fontSize: '200%'}} /></a> <Text/>
          <a href="https://t.me/vfprotocol" target="blank" style={{paddingLeft:"25px"}}><img src={telegramIcon} alt="Telegram" width={35} /></a> <Text/>
          <a href="https://linktr.ee/vfprotocol" target="blank" style={{paddingLeft:"25px"}}><img src={linktreeIcon} alt="Linktree" width={35} /></a> </div>
          <div class="fixed-widgets">
          <HelpPopover/>
          </div>
          <br></br>
          <br></br>
          </div>
          </Route>

          {/*Step 3 - This is the Pending Sales Page where users can View Handshakes*/}
          <Route exact path="/PendingSales">
        <div style={{ maxWidth: 640, minWidth:620, margin: "auto", marginTop: 32, paddingBottom: 32 }}>
        {address ? null : <Alert message="Demo Mode Active" description="Connect Your Wallet to Access Your Handshakes" type="success" />}
        <ActiveAccepts
          address={address}
          ensProvider={mainnetProvider}
          userSigner={userSigner} 
          gasPrice={gasPrice}
          writeContracts={writeContracts}
          />
          <HCardSellerList
            address={address}
            ensProvider={mainnetProvider}
            userSigner={userSigner} 
            gasPrice={gasPrice}
            writeContracts={writeContracts}
          />
          </div>
          <div style={{ maxWidth: 640, minWidth:620, margin: "auto", marginTop: 32, paddingBottom: 32 }}>
          <AcceptedHandshakes
          address={address}
          ensProvider={mainnetProvider}
          userSigner={userSigner} 
          gasPrice={gasPrice}
          writeContracts={writeContracts}
          />
          </div>
          <br />
          <br />
          <div>
          <h3><a href="https://twitter.com/vfprotocol" target="blank">🎉 VF Protocol</a> - Made with ❤️ by <a href="https://linktr.ee/camarmstrong" target="blank">Cameron</a></h3>
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: '10px'}}>
          <a href="https://twitter.com/vfprotocol" target="blank"><TwitterOutlined style={{ fontSize: '200%'}} /></a> <Text/>
          <a href="https://t.me/vfprotocol" target="blank" style={{paddingLeft:"25px"}}><img src={telegramIcon} alt="Telegram" width={35} /></a> <Text/>
          <a href="https://linktr.ee/vfprotocol" target="blank" style={{paddingLeft:"25px"}}><img src={linktreeIcon} alt="Linktree" width={35} /></a> </div>
          <div class="fixed-widgets">
          <HelpPopover/>
          </div>
          <br></br>
          <br></br>
          </div>
        </Route>

        <Route exact path="/HowItWorks">
          <br></br>
          <h1>A Handshake is an NFT Private Sale Created with VF Protocol</h1>
          <Divider/>
          <h1>How It Works:</h1>
          <div style={{position: 'relative', paddingBottom: '40%', height: 0, width: '60%', margin: 'auto'}}><iframe style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} src="https://www.youtube.com/embed/4-K2QjUwE6c" title="VF Protocol: How it Works" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>
          {/* <iframe webkitallowfullscreen mozallowfullscreen allowfullscreen ></iframe> */}
  
          {/* <iframe src="https://www.youtube.com/watch?v=zylY8OkmL3Q" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}></iframe> */}
          {/* <div style={{textAlign: "center", width: "120%"}}>
          <div style={{textAlign: "left", display: "inline-block"}}> */}
          <h2 style={{paddingTop: '20px'}}>1. 🔎 Find a friend that wants to buy your sick NFT</h2>
          <h2>2. ✨ <a href="https://www.vfprotocol.com" target="blank">Create a Handshake</a> for them to accept</h2>
          <h2>You'll set:</h2> 
          <div style={{textAlign: "center"}}>
          <div style={{textAlign: "left", display: "inline-block"}}>
          <h3>• The NFT you're selling</h3> 
          <h3>• The Buyer's ENS/Address </h3>
          <h3>• The Price the Buyer will Pay</h3>
          </div>
          </div>
          <h2>3. ✅ Your friend now has 60 minutes to <a href="https://www.vfprotocol.com/PendingSales" target="blank">Accept the Handshake</a></h2>
          {/* </div>
          </div> */}
          <br></br>
          <h2>🎉 You are now ready to <a href="https://www.vfprotocol.com/withdraw" target="blank">Withdraw your Funds</a> immediately or...</h2> 
          <h2>You can do multiple Handshakes and then withdraw all the funds at once to save gas!</h2> 
          <br/>
          <div>
          <Button
            type="primary"
            shape="round"
            size="large" 
          >
            <Link to="/">Create a Handshake Now!</Link>
          </Button>
          </div>
          <br/>
          <br></br>
          <h3><a href="https://twitter.com/vfprotocol" target="blank">🎉 VF Protocol</a> - Made with ❤️ by <a href="https://linktr.ee/camarmstrong" target="blank">Cameron</a></h3>
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: '10px'}}>
          <a href="https://twitter.com/vfprotocol" target="blank"><TwitterOutlined style={{ fontSize: '200%'}} /></a> <Text/>
          <a href="https://t.me/vfprotocol" target="blank" style={{paddingLeft:"25px"}}><img src={telegramIcon} alt="Telegram" width={35} /></a> <Text/>
          <a href="https://linktr.ee/vfprotocol" target="blank" style={{paddingLeft:"25px"}}><img src={linktreeIcon} alt="Linktree" width={35} /></a> </div>
          <div class="fixed-widgets">
          <HelpPopover/>
          </div>
          <br></br>
          <br></br>

        </Route>
        {/*Step 4 - This is the Withdraw Page where users can withdraw funds*/}
        <Route exact path="/Withdraw">
          <div style={{ maxWidth: 640, minWidth:620, margin: "auto", marginTop: 32, paddingBottom: 32 }}>
          {address ? null : <Alert message="Demo Mode Active" description="Connect Your Wallet to Access Your Balance" type="success" />}
              <div>
                <WithdrawBalance
                address={address} 
                provider={localProvider} 
                price={price}
                contractAddress={vfprotocolv0}
                vfpBalance={vfpBalance} 
                />
              </div>
              <div style={{ maxWidth: 640, minWidth:620, margin: "auto", marginTop: 32, paddingBottom: 32 }}>
            <Button
                    shape="round"
                    size="large"
                    onClick={() => {
                      withdrawFundsNew(); 
                    }}
                  >
                    Withdraw Funds
                  </Button>
            </div>
        </div>
        <br />
          <br />
          <div>
          <h3><a href="https://twitter.com/vfprotocol" target="blank">🎉 VF Protocol</a> - Made with ❤️ by <a href="https://linktr.ee/camarmstrong" target="blank">Cameron</a></h3>
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: '10px'}}>
          <a href="https://twitter.com/vfprotocol" target="blank"><TwitterOutlined style={{ fontSize: '200%'}} /></a> <Text/>
          <a href="https://t.me/vfprotocol" target="blank" style={{paddingLeft:"25px"}}><img src={telegramIcon} alt="Telegram" width={35} /></a> <Text/>
          <a href="https://linktr.ee/vfprotocol" target="blank" style={{paddingLeft:"25px"}}><img src={linktreeIcon} alt="Linktree" width={35} /></a> </div>
          <div class="fixed-widgets">
          <HelpPopover/>
          </div>
          <br></br>
          <br></br>
          </div>
        </Route>

        {/*This is the About Page*/}
        <Route exact path="/About">
          <h1>Welcome to VF Protocol!</h1>
          <h3>We're going to make transaction fees <u>extinct.</u></h3>
          <h3>We're going to do that by building a zero fee payments network on DeFi rails.</h3>
          <h3><a href="https://vfprotocol.substack.com/" target="blank">If you want to learn more, subscribe to our "Building in Public" blog</a></h3>
          <h3>For now, we're building the best way to execute NFT private sales.</h3>
          <h3>And that's why you're here. On this page. Right now.</h3>
          <h3>(we're happy you joined us 😎)</h3>
          <Button type="primary" shape="round" href="https://twitter.com/vfprotocol" target="blank">Follow us on Twitter for updates!</Button>
          <div style={{paddingBottom: '10px'}} />
          <Divider/>
          <h1>Who We Are</h1>
          <a href="https://www.philosophicalfoxes.com/" target="blank"><Image width={200} src="https://i.imgur.com/O1SS2q2.png"  /></a>
          <h3><a href="https://linktr.ee/camarmstrong" target="blank">Cameron</a> is the Founder of VF Protocol.</h3> 
          <h3>He's a serial builder, self-taught dev, and  likes to eat low hanging fruit.</h3> 
          <h3>He sold his last startup, got his <a href="https://www.wysr.xyz/p/harvard-business-school-a-veteran" target="blank">MBA from Harvard Business School</a>, and was an Infantry Officer in the US Army.</h3>
          <h3>(More very fun people to be announced very soon...)</h3>
          <Divider/>
          <h1>How It Works:</h1>
          {/* <div style={{position: 'relative', paddingBottom: '50%', height: 0, width: '60%', margin: 'auto'}}><iframe src="https://www.loom.com/embed/e72dbad0c2544fa4a6434d505ae77a39" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}></iframe></div> */}
          <div style={{position: 'relative', paddingBottom: '40%', height: 0, width: '60%', margin: 'auto'}}><iframe style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} src="https://www.youtube.com/embed/4-K2QjUwE6c" title="VF Protocol: How it Works" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>
          <h2 style={{paddingTop: '20px'}}>1. 🔎 Find a friend that wants to buy your sick NFT</h2>
          <h2>2. ✨ Create a Handshake for them to accept</h2>
          <h2>You'll set:</h2> 
          <h3>• The NFT you're selling</h3> 
          <h3>• The Buyer's ENS/Address </h3>
          <h3>• The Price the Buyer will Pay</h3>
          <h2>3. ✅ Your friend now has 60 minutes to Accept the Handshake</h2>
          <br></br>
          <h2>🎉 You are now ready to Withdraw your Funds immediately or...</h2> 
          <h2>You can do multiple Handshakes and then withdraw all the funds at once to save gas!</h2> 
          <Divider/>
          <h2>Please <a href="https://twitter.com/vfprotocol" target="blank">reach out</a> if you need help, run into issues, or just want to talk</h2>
          <Divider/>
          <h3>If you're excited about a future without fees <a href="https://vfprotocol.substack.com/" target="blank">subscribe to our "Building in Public" blog,</a></h3> 
          <h3>read <a href="https://docsend.com/view/39g5fvm3rx9ucr8f" target="blank">our Litepaper</a> and <a href="https://docsend.com/view/hkyxxfpz25zyzxha" target="blank">Conceptual Overview</a></h3>
          <h3>and <a href="https://www.twitter.com/frozenfire42" target="blank">follow us on Twitter!</a></h3>
          <br/>
          <div>
          <Button
            type="primary"
            shape="round"
            size="large" 
          >
            <Link to="/">Create a Handshake Now!</Link>
          </Button>
          </div>
          <br></br>
          <h3><a href="https://twitter.com/vfprotocol" target="blank">🎉 VF Protocol</a> - Made with ❤️ by <a href="https://linktr.ee/camarmstrong" target="blank">Cameron</a></h3>
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: '10px'}}>
          <a href="https://twitter.com/vfprotocol" target="blank"><TwitterOutlined style={{ fontSize: '200%'}} /></a> <Text/>
          <a href="https://t.me/vfprotocol" target="blank" style={{paddingLeft:"25px"}}><img src={telegramIcon} alt="Telegram" width={35} /></a> <Text/>
          <a href="https://linktr.ee/vfprotocol" target="blank" style={{paddingLeft:"25px"}}><img src={linktreeIcon} alt="Linktree" width={35} /></a> </div>
          <div class="fixed-widgets">
          <HelpPopover/>
          </div>
          <br></br>
          <br></br>

        </Route>
          
                    {/*
                🎛 The Debug Page scaffolding is full of commonly used components
                this <Contract/> component will automatically parse your ABI
                and give you a form to interact with it locally
                *************KEEP COMMENTED OUT IN PRODUCTION***************
            */}
          {/* <Route exact path="/debug">

          <Contract
            name="BasicSale"
            price={price}
            signer={userSigner}
            provider={localProvider}
            address={address}
            blockExplorer={blockExplorer}
            contractConfig={contractConfig}
          />
          <Contract
            name={"YourCollectible"}
            price={price}
            signer={userSigner}
            provider={localProvider}
            address={address}
            blockExplorer={blockExplorer}
            contractConfig={contractConfig}
          />
        </Route> */}
      </Switch>

      {/* <ThemeSwitch /> */}

      {/* 👨‍💼 Your account is in the top right with a wallet at connect options */}
      <div style={{ position: "fixed", textAlign: "center", right: 8, top: 0, padding: 10 }}>
        <div style={{ display: "flex", flex: 1, alignItems: "start" }}>
          {USE_NETWORK_SELECTOR && (
            <div style={{ marginRight: 20 }}>
              <NetworkSwitch
                networkOptions={networkOptions}
                selectedNetwork={selectedNetwork}
                setSelectedNetwork={setSelectedNetwork}
              />
            </div>
          )}
          <Account
            useBurner={USE_BURNER_WALLET}
            address={address}
            localProvider={localProvider}
            userSigner={userSigner}
            mainnetProvider={mainnetProvider}
            price={price}
            web3Modal={web3Modal}
            loadWeb3Modal={loadWeb3Modal}
            logoutOfWeb3Modal={logoutOfWeb3Modal}
            blockExplorer={blockExplorer}
          />
          <SettingsDropdown 
          address={address}
          logoutOfWeb3Modal={logoutOfWeb3Modal}
          />
        </div>
        
      </div>

    </div>
  );
}

export default App;
