import React from "react";
import {Logo} from '../index.jsx';
import { Typography } from 'antd';


const { Title } = Typography;

// displays a page header

export default function Header({ link, title, subTitle }) {
  return (
    <a href={link} rel="noopener noreferrer">
      {/* <img 
      src="https://gateway.pinata.cloud/ipfs/QmTv5K6zGvZ3857aCoySC1e1XqciK6U7KEqx6xkq8wHoC4" 
      width={400}
      height={100}
      />  */}
      <Title style={{textAlign:"left", marginTop: 9, verticalAlign: "top"}}>{title}</Title>
    </a>
  );
}

Header.defaultProps = {
  link: "https://www.vfprotocol.com",
  title: "🎉VF Protocol",
};
